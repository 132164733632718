import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StripeProvider, Elements } from 'react-stripe-elements'
import { Box } from '@chatterbug/aaron'
import { Tabs, Tab, TabList, TabPanel } from '@chatterbug/aaron/tabs'
import ContentCard from 'src/components/ContentCard/ContentCard'
import { t } from 'src/lib/i18n-react'

import CreditCardForm from './subcomponents/CreditCardForm'
import IbanForm from './subcomponents/IbanForm'
import PaymentRequestButton from './subcomponents/PaymentRequestButton'
import StartSubscriptionButton from './subcomponents/StartSubscriptionButton'
import AddPaymentSourceButton from './subcomponents/AddPaymentSourceButton'

const getPaymentIdFromResponse = (response) =>
  (response.token && response.token.id) ||
  (response.source && response.source.id)

class PaymentForm extends React.Component {
  state = {
    stripe: null,
    paymentRequest: undefined,
    formData: {
      name: '',
      email: '',
    },
  }

  async componentDidMount() {
    this.setupStripe()
  }

  setupStripe = async () => {
    const stripe = window.Stripe(this.props.stripePublishedKey)
    this.setState({
      stripe,
      loading: false,
      paymentRequest: await this.paymentRequest(stripe),
    })
  }

  paymentRequest = async (stripe) => {
    const { plan, discount } = this.props

    if (typeof plan === 'undefined') {
      return undefined
    }

    const paymentRequest = stripe.paymentRequest({
      // https://stripe.com/docs/stripe.js#the-payment-request-object
      country: 'US',
      currency: plan.currency.toLowerCase(),
      total: {
        label: `Chatterbug ${plan.name} Subscription`,
        amount: discount && discount.total ? discount.total.amount : plan.price,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    })

    if ((await paymentRequest.canMakePayment()) === null) {
      return undefined
    }
    return paymentRequest
  }

  handleSubscribeClick = async (formType, stripe) => {
    this.setState({ loading: true, error: undefined })

    let response
    if (formType === 'card') {
      response = await stripe.createToken()
    } else {
      const { name, email } = this.state.formData
      response = await stripe.createSource({
        type: 'sepa_debit',
        currency: 'EUR',
        owner: {
          name,
          email,
        },
      })
    }

    if (response.error) {
      this.setState({ loading: false, error: response.error.message })
      return
    }

    this.props.onStripeResponse(getPaymentIdFromResponse(response), response)
  }

  render() {
    const {
      plan,
      onStripeResponse,
      paymentSource,
      paymentMethods,
      buttonText,
    } = this.props
    const { loading } = this.state

    const FormButton =
      paymentSource === 'new' ? StartSubscriptionButton : AddPaymentSourceButton

    // We limit SEPA payments to a few countries. Adding `?iban=true` to the address
    // will skip this limitation.
    const showIBANPaymentOption =
      paymentMethods.includes('iban') ||
      window.location.search.includes('iban=true')

    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <SC.Container>
            {typeof this.state.paymentRequest !== 'undefined' && (
              <div>
                <h2>{t('Choose a payment method')}</h2>
                <PaymentRequestButton
                  paymentRequest={this.state.paymentRequest}
                  onStripeResponse={(response) =>
                    onStripeResponse(
                      getPaymentIdFromResponse(response),
                      response
                    )
                  }
                />
                <p>{t('Or enter your payment details below')}</p>
              </div>
            )}

            <Tabs>
              <TabList
                style={{
                  display: showIBANPaymentOption ? 'flex' : 'none',
                  marginBottom: '16px',
                }}
              >
                <Tab>
                  <i className="fa fa-credit-card" /> {t('Card')}
                </Tab>
                <Tab>
                  <i className="fa fa-bank" /> {t('IBAN')}
                </Tab>
              </TabList>

              <TabPanel>
                <Box width="100%">
                  <CreditCardForm />
                </Box>
                {!this.state.stripe ? (
                  t('Loading...')
                ) : (
                  <FormButton
                    disabled={loading}
                    plan={plan}
                    onClick={(stripe) =>
                      this.handleSubscribeClick('card', stripe)
                    }
                  >
                    {buttonText}
                  </FormButton>
                )}
              </TabPanel>
              <TabPanel>
                <IbanForm
                  formData={this.state.formData}
                  onFormChange={(formData) =>
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        ...formData,
                      },
                    })
                  }
                />
                {!this.state.stripe ? (
                  t('Loading...')
                ) : (
                  <FormButton
                    disabled={loading}
                    plan={plan}
                    onClick={(stripe) =>
                      this.handleSubscribeClick('iban', stripe)
                    }
                  >
                    {buttonText}
                  </FormButton>
                )}
              </TabPanel>

              {this.state.error && <SC.Error>{this.state.error}</SC.Error>}
            </Tabs>
          </SC.Container>
        </Elements>
      </StripeProvider>
    )
  }
}

const SC = {}
SC.Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #9fc5ce;

  h2 {
    font-size: 20px;
    font-weight: bold;
    color: #303030;
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 8px;
  }
`
SC.Actions = styled.div`
  margin-top: 32px;
  text-align: center;
`
SC.SubscriptionCost = styled.div`
  margin-top: 8px;
  color: #b3b3b3;
  font-size: 14px;

  b {
    color: #aa8cea;
    font-size: 18px;
  }
`
SC.Error = styled.div`
  margin-top: 8px;
  color: #eb1c26;
  text-align: center;
`

PaymentForm.defaultProps = {
  paymentSource: 'additional',
  paymentMethods: ['card'],
  coupon: {},
}

PaymentForm.propTypes = {
  stripePublishedKey: PropTypes.string.isRequired,
  plan: PropTypes.object,
  discount: PropTypes.object,
  /**
   * Called when we receive a successful response from Stripe, after the user taps
   * on the button. `<PaymentForm onStripeResponse={(newSourceId, response) => { /* * /}} />`
   */
  onStripeResponse: PropTypes.func.isRequired,
  /** We use the same form to add another payment source */
  paymentSource: PropTypes.oneOf(['new', 'additional']),
  paymentMethods: PropTypes.arrayOf(PropTypes.oneOf(['card', 'iban'])),

  /** overwrite the default text */
  buttonText: PropTypes.string,
}

export default PaymentForm
