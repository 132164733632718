import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { injectStripe } from 'react-stripe-elements'
import { css } from 'styled-components'

import { Button } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'

const AddPaymentSourceButton = ({ stripe, onClick, children, ...others }) => {
  return (
    <SC.Actions>
      <Button
        // we don't use design tokens here because this button is used on pages using both old and new theme
        css={css`
          background-color: #00e6c2;
          color: white;
          :hover {
            background-color: #49eed4;
          }
        `}
        size="large"
        onClick={(e) => {
          e.preventDefault()
          onClick(stripe)
        }}
        {...others}
      >
        {children || t('Add payment source')}
      </Button>
    </SC.Actions>
  )
}

const SC = {}
SC.Actions = styled.div`
  margin-top: 32px;
  text-align: center;
`

AddPaymentSourceButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default injectStripe(AddPaymentSourceButton)
